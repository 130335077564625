import { RpcDefinitions } from '../server/server.js';
import { HttpRpcDefinition } from '../server/http-rpc.js';
import {
  BadRequestError,
  RpcFetchClientErrorHandler,
  RpcFetchClientOptions,
} from './client.js';
import { RpcClientApp } from './app.js';
import { fetchHttpClient } from './browser-client.js';
import { bodyAsJson } from './body-as-json.js';
import { validate } from '../../typing/validate.js';
import { object } from '../../typing/object.js';
import { string } from '../../typing/string.js';
import { record } from '../../typing/record.js';
import { CustomError } from '../../custom-error.js';
import { newRootNode, parsePath, stringifyPath } from '../http-tree.js';
import { RpcClientFunction } from './function.js';
import { HttpMethod } from './http-method.js';
import { failNever } from '../../utils/fail-never.js';

export function rpcFetchClient<T extends RpcDefinitions>(
  definition: T,
  httpDefinition: HttpRpcDefinition<T>,
  options: RpcFetchClientOptions,
): RpcClientApp<T> {
  const client = fetchHttpClient({ baseUrl: options.url, env: options.env });
  const errorHandler: RpcFetchClientErrorHandler =
    options?.errorHandler ??
    (async (res) => {
      const contentType = res.header('Content-Type');
      if (contentType === 'application/json') {
        const body = await bodyAsJson(res);
        if (res.statusCode === 400) {
          const [err, data] = validate(
            body,
            object('BadRequest', {
              message: string(),
              data: record(string(), string()),
            }),
          );
          if (err) {
            throw err;
          }
          throw new CustomError(data.message, null, data.data);
        }

        return new BadRequestError(res.headers(), body);
      }

      return new BadRequestError(res.headers(), await res.bodyAsString());
    });

  const root = newRootNode();

  function mapToRequestMethod(method: HttpMethod) {
    if (method === 'POST') {
      return 'post' as const;
    } else if (method === 'GET') {
      return 'get' as const;
    } else if (method === 'PUT') {
      return 'put' as const;
    } else if (method === 'HEAD') {
      return 'head' as const;
    } else if (method === 'OPTIONS') {
      return 'options' as const;
    } else if (method === 'DELETE') {
      return 'head' as const;
    } else if (method === 'PATCH') {
      return 'patch';
    } else {
      failNever(method, 'unknown http method');
    }
  }

  return Object.entries(definition).reduce<RpcClientApp<any>>(
    (app, [key, fn]) => {
      const rpcClient: RpcClientFunction<any, any> = {
        async call(arg: any, signal: AbortSignal): Promise<any> {
          const http = httpDefinition[key];
          if (!http) {
            throw new CustomError('http definition not found', null, { key });
          }

          const [err, data] = validate(arg, fn.input);
          if (err) {
            throw err;
          }

          const pathNode = parsePath(root, http.path, fn.input, http.mapping);
          const parsedPath = stringifyPath(pathNode, data);

          let request = client;

          const body: Record<string, any> = {};
          for (const [mappingName, mapping] of Object.entries(http.mapping)) {
            const value = data[mappingName];
            if (value === undefined) {
              continue;
            }
            if (mapping.type == 'body') {
              body[mappingName] = value;
            } else if (mapping.type === 'header') {
              request = request.header(
                mappingName,
                btoa(JSON.stringify(value)),
              );
            } else if (mapping.type === 'query') {
              request = request.query(mappingName, btoa(JSON.stringify(value)));
            }
          }

          if (Object.keys(body).length > 0) {
            request = request.body(JSON.stringify(body));
          }

          const result = await request[mapToRequestMethod(http.method)]({
            path: parsedPath,
            signal,
          });

          if (fn.type === 'return') {
            if (result.statusCode === 404) {
              return null;
            } else if (result.statusCode === 200) {
              return await bodyAsJson(result);
            }
          } else if (fn.type === 'cmd') {
            if (result.statusCode === 204) {
              return;
            }
          } else if (fn.type === 'continuous') {
            return {
              result: async function* () {
                for await (const data of result.bodyAsEventStream()) {
                  for (const line of data.split('\n')) {
                    if (line.startsWith('data: ')) {
                      yield JSON.parse(line.substring('data: '.length));
                    }
                  }
                }
              },
            };
          }

          const error = await errorHandler(result);
          if (error) {
            throw error;
          }
        },
      };
      app[key] = rpcClient;
      return app;
    },
    {},
  );
}
