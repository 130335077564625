import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { TodoStateService } from '../todo-state.service';
import { AionStateService } from '../aion/aion-state.service';
import { ActivatedRoute, Params } from '@angular/router';
import { todoStream } from '../../state/todo/stream';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { groupStream } from '../../state/group/stream.js';
import { fromAsyncIterable } from '../signal-from-destroy-ref.js';
import { map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { StreamInterface } from '@aion/core/stream/stream-interface.js';
import { Todo, TodoState } from '../../state/todo/state.js';
import { TodoEvents } from '../../state/todo/events.js';
import { personalTodoAggregation } from '../../state/aggregation/personal-todo.js';
import { AggregateInterface } from '@aion/core/aggregation/aggregate-interface.js';
import { timedSignal } from '@aion/core/utils/timedSignal.js';
import { BrowseResult } from '@aion/core/storage/browse-result.js';

type Stream =
  | { type: 'stream'; interface: StreamInterface<TodoState, TodoEvents> }
  | { type: 'aggregation'; interface: AggregateInterface<TodoState> };

@Component({
  selector: 'app-todo-page',
  templateUrl: './todo-page.component.html',
  styleUrls: ['./todo-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoPageComponent {
  todoState = inject(TodoStateService);
  aion = inject(AionStateService);
  route = inject(ActivatedRoute);
  destroyRef = inject(DestroyRef);

  stream$ = this.route.params.pipe(
    map<Params, Stream>((params) => {
      if (!params || params['group'] === 'personal') {
        return {
          type: 'stream',
          interface: this.todoState.management.getStream(todoStream, {}),
        };
      } else if (params['group'] === 'all') {
        return {
          type: 'aggregation',
          interface: this.todoState.management.getAggregation(
            personalTodoAggregation,
            {},
          ) as any,
        };
      } else {
        return {
          type: 'stream',
          interface: this.todoState.management.getStream(groupStream, {
            args: {
              groupId: params['group'],
            },
          }) as any, // TODO typing
        };
      }
    }),
  );
  stream = toSignal(this.stream$);

  todos$: Observable<BrowseResult<Todo>> = this.stream$.pipe(
    switchMap((stream) =>
      fromAsyncIterable((signal) =>
        stream.interface.observe('todos').index('date').observeRange(null, {
          take: 10,
          dir: 'prev',
          signal,
        }),
      ),
    ),
    tap((d) => console.log('todos', d)),
    takeUntilDestroyed(this.destroyRef),
    shareReplay(1),
  );

  todos = toSignal(this.todos$);

  async checkedChanged(stream: Stream, evt: { todo: Todo; checked: boolean }) {
    if (stream.type === 'aggregation') {
      return;
    }

    if (evt.checked) {
      await stream.interface.push(
        'complete-todo',
        { id: evt.todo.id },
        { signal: timedSignal() },
      );
    } else {
      await stream.interface.push(
        'incomplete-todo',
        { id: evt.todo.id },
        { signal: timedSignal() },
      );
    }
  }
}
