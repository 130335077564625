import { RuntimeEnv } from '../../runtime-env.js';
import { HttpClientResponse } from './http-client-response.js';

export type RpcFetchClientErrorHandler = (
  res: HttpClientResponse,
) => Promise<Error | null>;

export interface RpcFetchClientOptions {
  errorHandler?: RpcFetchClientErrorHandler;
  url: string;
  env: RuntimeEnv;
}

export class BadRequestError extends Error {
  constructor(
    public headers: { [key: string]: string | string[] },
    public body: any,
  ) {
    super('bad request - ' + body);
  }
}
