import { object } from '../../../typing/object.js';
import { string } from '../../../typing/string.js';
import { union } from '../../../typing/union.js';
import { literal } from '../../../typing/literal.js';
import { optional } from '../../../typing/optional.js';
import { array } from '../../../typing/array.js';
import { number } from '../../../typing/number.js';
import { boolean } from '../../../typing/boolean.js';
import { record } from '../../../typing/record.js';
import { JsonSchema } from './json-schema.js';
import { lazyType } from '../../../typing/lazy-type.js';
import { UnionTypeDescription } from '../../../typing/union-type-description.js';

export const JsonSchemaType: UnionTypeDescription<JsonSchema> = lazyType<
  UnionTypeDescription<JsonSchema>
>(() =>
  union('JsonSchema', [
    JsonStringSchemaType,
    JsonNumberSchemaType,
    JsonBooleanSchemaType,

    JsonArraySchemaType,
    JsonObjectSchemaType,
    JsonRefSchemaType,
    JsonOneOfSchemaType,
  ]),
);

export const JsonRefSchemaType = object('JsonSchemaRef', {
  $ref: string(),
});

export const JsonStringSchemaType = object('JsonString', {
  type: literal('string'),
  format: optional(literal('date-time')),
  enum: optional(array(string())),
});

export const JsonNumberSchemaType = object('JsonNumber', {
  type: literal('number'),
  enum: optional(array(number())),
});

export const JsonBooleanSchemaType = object('JsonBoolean', {
  type: literal('boolean'),
  enum: optional(array(boolean())),
});

export const JsonArraySchemaType = object('JsonArray', {
  type: literal('array'),
  items: JsonSchemaType,
});

export const JsonObjectSchemaType = object('JsonObject', {
  type: literal('object'),
  properties: optional(record(string(), JsonSchemaType)),
  additionalProperties: optional(JsonSchemaType),
  required: optional(array(string())),
});

export const JsonOneOfSchemaType = object('JsonOneOf', {
  oneOf: array(JsonSchemaType),
});
