import { HttpMiddleware } from './http-middleware.js';
import { HttpHandler } from './http-handler.js';
import { HttpRequest } from './http-request.js';
import { HttpResponse } from './http-response.js';
import { CustomError } from '../custom-error.js';
import { HttpMethod } from './client/http-method.js';
import { createLogger } from '../logger.js';
import { getOrCreate } from '../utils/get-or-create.js';
import { failNever } from '../utils/fail-never.js';
import {
  JsonBooleanSchema,
  JsonNumberSchema,
  JsonStringSchema,
} from './server/openapi/json-schema.js';
import { TypeDescription } from '../typing/type-description.js';
import { HttpSource } from './server/http-rpc.js';
import { ObjectTypeDescription } from '../typing/object-type-description.js';
import { getOrFail } from '../realm/get-or-fail.js';
import { validate } from '../typing/validate.js';
import { StringTypeDescription } from '../typing/string-type-description.js';
import { NumberTypeDescription } from '../typing/number-type-description.js';
import { BooleanTypeDescription } from '../typing/boolean-type-description.js';
import { isRequiredType } from '../storage/memory/is-compatible-state-definitions.js';

export type PathSegment =
  | { path: string; type: 'path' }
  | {
      param: string;
      type: 'param';
      typeDescription:
        | StringTypeDescription
        | NumberTypeDescription
        | BooleanTypeDescription;
    };

export interface HttpTreeNode<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
> {
  readonly _req: Req;
  readonly _res: Res;
  readonly _params: Params;

  readonly queryArgs: QueryArgs;
  readonly headers: HeaderArgs;
  readonly routing: RoutingNode;
  readonly segments: PathSegment[];
  readonly middlewares: HttpMiddleware<any, any, any, any>[];
  readonly extras: Record<string, unknown>;
}

export type HeaderArgs = Record<
  string,
  {
    type:
      | StringTypeDescription
      | NumberTypeDescription
      | BooleanTypeDescription;
    required: boolean;
  }
>;

export type QueryArgs = Record<
  string,
  {
    type:
      | StringTypeDescription
      | NumberTypeDescription
      | BooleanTypeDescription;
    required: boolean;
  }
>;

export interface HttpLeafNode<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
> {
  readonly _req: Req;
  readonly _res: Res;
  readonly _params: Params;

  readonly method: HttpMethod;
  readonly routing: RoutingNode;
  readonly segments: PathSegment[];
  readonly queryArgs: QueryArgs;
  readonly headers: HeaderArgs;
  readonly middlewares: HttpMiddleware<any, any, any, any>[];
  readonly extras: Record<string, unknown>;
}

export function newRootNode(): HttpTreeNode<HttpRequest, HttpResponse, {}> {
  return {
    _req: undefined as any,
    _res: undefined as any,
    _params: undefined as any,
    routing: {
      paramNode: null,
      methods: {},
      pathNodes: {},
      segments: [],
    },
    queryArgs: {},
    headers: {},
    segments: [],
    middlewares: [],
    extras: {},
  };
}

export function getValueResolver(
  path: string,
  input: ObjectTypeDescription<any>,
  mapping: Record<string, HttpSource>,
) {
  const parts = path.split('/').filter((s) => !!s);

  const paramsResolvers: Record<
    string,
    {
      transformer(val: string): any;
      index: number;
    }
  > = {};
  const queryResolvers: Record<
    string,
    {
      transformer(val: string | string[] | null): any;
    }
  > = {};
  const headerResolvers: Record<
    string,
    {
      transformer(val: string | string[] | null): any;
    }
  > = {};

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part && part.startsWith('{') && part.endsWith('}')) {
      const name = part.substring(1, part.length - 1);
      const type = getOrFail(input.object, name);
      paramsResolvers[name] = {
        index: i,
        transformer(val: string): any {
          const [err, result] = validate(val, type);
          if (err) {
            throw err;
          }
          return result;
        },
      };
    }
  }

  for (const [mappingName, source] of Object.entries(mapping)) {
    const type = getOrFail(input.object, mappingName);
    if (source.type === 'path') {
      if (!paramsResolvers[mappingName]) {
        throw new CustomError('missing params in path', null, {
          name: mappingName,
        });
      }
    } else if (source.type === 'header') {
      headerResolvers[mappingName] = {
        transformer(val: string | string[] | null): any {
          const value = val
            ? typeof val === 'string'
              ? JSON.parse(atob(val))
              : null
            : null;
          const [err, result] = validate(value, type);
          if (err) {
            throw err;
          }
          return result;
        },
      };
    } else if (source.type === 'query') {
      queryResolvers[mappingName] = {
        transformer(val: string | string[] | null): any {
          const value = val
            ? typeof val === 'string'
              ? JSON.parse(atob(val))
              : null
            : null;
          const [err, result] = validate(value, type);
          if (err) {
            throw err;
          }
          return result;
        },
      };
    }
  }

  return {
    query: (req: HttpRequest, key: string) => {
      const resolver = getOrFail(queryResolvers, key);
      const value = req.query(key);
      return resolver.transformer(value);
    },
    header: (req: HttpRequest, key: string) => {
      const resolver = getOrFail(headerResolvers, key);
      const value = req.header(key);
      return resolver.transformer(value);
    },
    params: (req: HttpRequest, key: string) => {
      const resolver = getOrFail(paramsResolvers, key);
      const part = req.paths[resolver.index];
      if (!part) {
        throw new CustomError(`params not found`, null, { key });
      }
      return resolver.transformer(part);
    },
  };
}

export function parsePath(
  node: HttpTreeNode<HttpRequest, HttpResponse, any>,
  path: string,
  input: ObjectTypeDescription<any>,
  mapping: Record<string, HttpSource>,
): HttpTreeNode<HttpRequest, HttpResponse, any> {
  const parts = path.split('/').filter((s) => !!s);

  let current = node;
  for (const part of parts) {
    if (part.startsWith('{') && part.endsWith('}')) {
      const name = part.substring(1, part.length - 1);
      const pathMapping = mapping[name];
      if (!pathMapping || pathMapping.type !== 'path') {
        throw new CustomError(`path part is not mapped to path`, null, {
          name,
        });
      }
      current = paramNode(
        current,
        part.substring(1, part.length - 1),
        getOrFail(input.object, name),
      );
    } else {
      current = pathNode(current, part);
    }
  }
  return current;
}

export function getParamsSchema(
  name: string,
  type: TypeDescription,
): JsonStringSchema | JsonNumberSchema | JsonBooleanSchema {
  if (type.type === 'string') {
    return { type: 'string' };
  } else if (type.type === 'boolean') {
    return { type: 'boolean' };
  } else if (type.type === 'number') {
    return { type: 'number' };
  } else {
    throw new CustomError('type not supported for path params schema', null, {
      type,
      name,
    });
  }
}

export function stringifyPath<Params>(
  node: HttpTreeNode<HttpRequest, HttpResponse, Params>,
  params: Params,
): string {
  let path = '';
  for (const segment of node.segments) {
    if (segment.type === 'path') {
      path += path.length === 0 ? segment.path : '/' + segment.path;
    } else {
      path +=
        path.length === 0
          ? (params as any)[segment.param]
          : '/' + (params as any)[segment.param];
    }
  }
  return path;
}

export interface RoutingNode {
  pathNodes: Record<string, RoutingNode>;
  paramNode: RoutingNode | null;
  readonly segments: PathSegment[];
  readonly methods: Record<string, RoutingLeafNode>;
}

export interface RoutingLeafNode {
  readonly method: HttpMethod;
  readonly segments: PathSegment[];
  readonly queryArgs: QueryArgs;
  readonly headers: HeaderArgs;
  readonly handler: HttpHandler<any, any, any>;
  readonly extras: Record<string, unknown>;
}

export function createRouter<
  Request extends HttpRequest,
  Response extends HttpResponse,
>(node: HttpTreeNode<HttpRequest, HttpResponse, {}>) {
  return {
    async handle(req: Request, res: Response): Promise<Response> {
      let currentNode = node.routing;
      const currentParams: string[] = [];

      for (const nextPath of req.paths) {
        const childNode = currentNode.pathNodes[nextPath];
        if (childNode) {
          currentNode = childNode;
        } else if (currentNode.paramNode) {
          currentParams.push(nextPath);
          currentNode = currentNode.paramNode;
        } else {
          return res.statusCode(404);
        }
      }

      const handler = currentNode.methods[req.method];
      if (!handler) {
        return res.statusCode(404);
      }

      return handler.handler(req, res, currentParams);
    },
  };
}

export function useMiddleware<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
  NewReq extends HttpRequest,
  NewRes extends HttpResponse,
>(
  node: HttpTreeNode<Req, Res, Params>,
  middleware: HttpMiddleware<Req, Res, NewReq, NewRes>,
): HttpTreeNode<NewReq, NewRes, Params> {
  if (node.middlewares.indexOf(middleware) >= 0) {
    return node as any;
  }

  return {
    ...node,
    middlewares: [middleware, ...node.middlewares],
  } as any;
}

export function useLeafMiddleware<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
  NewReq extends HttpRequest,
  NewRes extends HttpResponse,
>(
  node: HttpLeafNode<Req, Res, Params>,
  middleware: HttpMiddleware<Req, Res, NewReq, NewRes>,
): HttpLeafNode<NewReq, NewRes, Params> {
  if (node.middlewares.indexOf(middleware) >= 0) {
    return node as any;
  }

  return {
    ...node,
    middlewares: [middleware, ...node.middlewares],
  } as any;
}

export function paramNode<Node extends HttpTreeNode<any, any, any>>(
  node: Node,
  name: string,
  type: TypeDescription,
): Node {
  return {
    ...node,
    parent: node,
    segments: [
      ...node.segments,
      {
        param: name.toString(),
        type: 'param',
        typeDescription: type,
      },
    ],
  };
}

export function headerArg<Node extends HttpTreeNode<any, any, any>>(
  node: Node,
  name: string,
  type: TypeDescription,
): Node {
  return {
    ...node,
    headers: {
      ...node.headers,
      [name]: { type, required: isRequiredType(type) },
    },
  };
}

export function queryArg<Node extends HttpTreeNode<any, any, any>>(
  node: Node,
  name: string,
  type: TypeDescription,
): Node {
  return {
    ...node,
    queryArgs: {
      ...node.queryArgs,
      ...{ [name]: { type, required: isRequiredType(type) } },
    },
  };
}

function getPathSegment(path: string) {
  const paths: PathSegment[] = path
    .split('/')
    .filter((s) => !!s)
    .map((s) => ({ path: s, type: 'path' }));
  return paths;
}

export function pathNode<
  Request extends HttpRequest,
  Response extends HttpResponse,
  Params,
>(
  node: HttpTreeNode<Request, Response, Params>,
  path: string,
): HttpTreeNode<Request, Response, Params> {
  const segments = getPathSegment(path);

  return {
    ...node,
    segments: [...node.segments, ...segments],
  };
}

export function nodeMethod<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
>(
  method: HttpMethod,
  node: HttpTreeNode<Req, Res, Params>,
): HttpLeafNode<Req, Res, Params> {
  return {
    ...node,
    method,
  };
}

function pathDescription(segments: PathSegment[]): string {
  return segments
    .map((s) => (s.type === 'param' ? `:${s.param}` : s.path))
    .join('/');
}

const logger = createLogger('http-tree');

export function addHandler<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
>(
  node: HttpLeafNode<Req, Res, Params>,
  handler: HttpHandler<Req, Res, Params>,
) {
  const description = pathDescription(node.segments);
  logger.debug(`register handler on [${node.method}] ${description}`);

  let routingNode = node.routing;
  const currentSegments = [...routingNode.segments];
  for (const segment of node.segments) {
    currentSegments.push(segment);
    if (segment.type === 'path') {
      if (routingNode.paramNode) {
        throw new CustomError(
          'cant use path and param node on same level',
          null,
          {
            conflict: routingNode,
            path: pathDescription(currentSegments),
          },
        );
      }
      routingNode = getOrCreate(routingNode.pathNodes, segment.path, () => ({
        pathNodes: {},
        paramNode: null,
        methods: {},
        segments: [...currentSegments],
      }));
    } else if (segment.type === 'param') {
      if (routingNode.paramNode) {
        routingNode = routingNode.paramNode;
      } else {
        if (Object.keys(routingNode.pathNodes).length > 0) {
          throw new CustomError(
            'cant use path and param node on same level',
            null,
            {
              conflict: routingNode,
              path: pathDescription(currentSegments),
            },
          );
        }

        const newNode: RoutingNode = {
          pathNodes: {},
          paramNode: null,
          methods: {},
          segments: [...currentSegments],
        };
        routingNode.paramNode = newNode;
        routingNode = newNode;
      }
    } else {
      failNever(segment, 'unknown segment');
    }
  }

  if (routingNode.methods[node.method]) {
    throw new CustomError('handler already registered on route', null, {
      method: node.method,
      path: pathDescription,
    });
  }

  let routingHandler: HttpHandler<Req, Res, Params> = handler;
  if (node.middlewares.length > 0) {
    for (const middleware of node.middlewares) {
      routingHandler = createMiddlewareHandler(middleware, routingHandler);
    }
  }

  routingNode.methods[node.method] = {
    handler: routingHandler,
    method: node.method,
    extras: node.extras,
    segments: currentSegments,
    headers: node.headers,
    queryArgs: node.queryArgs,
  };
}

function createMiddlewareHandler(
  middleware: HttpMiddleware<any, any, any, any>,
  nextHandler: HttpHandler<any, any, any>,
): HttpHandler<any, any, any> {
  return (req, res, params) => {
    return middleware({
      req,
      res,
      next: async (newReq, newRes) => nextHandler(newReq, newRes, params),
    });
  };
}
