import { defineHttpRpc } from '@aion/core/rpc/server/http-rpc.js';
import { rpcManagementDefinition } from './rpc-management-definition.js';

export const rpcManagementHttpDefinition = defineHttpRpc(
  rpcManagementDefinition,
  {
    replayStream: {
      method: 'POST',
      path: `/api/{tenant}/stream/replay`,
      mapping: {
        tenant: { type: 'path' },
        token: { type: 'header' },
        version: { type: 'body' },
        pattern: { type: 'body' },
        realm: { type: 'body' },
      },
      openapi: {
        tags: ['management'],
        operationId: 'uploadApp',
        description: 'Upload an app definition',
      },
    },
    upload: {
      method: 'POST',
      path: `/api/{tenant}/upload/{name}`,
      mapping: {
        tenant: { type: 'path' },
        name: { type: 'path' },
        auths: { type: 'body' },
        aggregations: { type: 'body' },
        streams: { type: 'body' },
        token: { type: 'header' },
      },
      openapi: {
        tags: ['management'],
        operationId: 'uploadApp',
        description: 'Upload an app definition',
      },
    },
    iterateStreams: {
      method: 'GET',
      path: `/api/{tenant}/stream/list`,
      mapping: {
        tenant: { type: 'path' },
        token: { type: 'header' },
        realm: { type: 'query' },
        version: { type: 'query' },
        pattern: { type: 'query' },
      },
      openapi: {
        tags: ['management'],
        operationId: 'iterateStreams',
        description: 'Iterate stream information',
      },
    },
  },
);
