import { TypeDescription } from '../../typing/type-description.js';
import { CustomError } from '../../custom-error.js';
import { assert } from '../../typing/assert.js';

export function parseJwt<Type extends TypeDescription>(
  token: string,
  partIndex: number,
  type: Type,
): Type['_output'] {
  const tokenPart = token.split('.')[partIndex];
  if (!tokenPart) {
    throw new Error('invalid format: token does not have enough parts');
  }

  if (type.type === 'string') {
    return tokenPart;
  }

  let content = '';
  try {
    content = atob(tokenPart);
  } catch (e) {
    throw new CustomError('invalid format', e, {});
  }

  let data: unknown;
  try {
    data = JSON.parse(content);
  } catch (e) {
    throw new CustomError('invalid json format', e, {});
  }

  assert(data, type);

  return data;
}
