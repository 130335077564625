import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { record } from '../typing/record.js';
import { AuthProviders } from './auth-providers.js';
import { TypeOf } from '../typing/type-of.js';

export const AuthDeclaration = object('AuthDeclaration', {
  name: string(),
  providers: record(string(), AuthProviders),
});
export type AuthDeclaration = TypeOf<typeof AuthDeclaration>;
