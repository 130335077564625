import { EventAuth } from './event-auth.js';
import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { date } from '../typing/date.js';
import { record } from '../typing/record.js';
import { TypeOf } from '../typing/type-of.js';
import { StateValue } from '../storage/state-value.js';

export const EventRecord = object('EventRecord', {
  id: string(),
  data: record(string(), StateValue),
  //random: EventRandomSeed,
  event: string(),
  storedAt: date(),
  createdAt: date(),
  annotations: record(string(), string()),
  auth: EventAuth,
  authId: string(),
});
export type EventRecord = TypeOf<typeof EventRecord>;
