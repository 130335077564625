import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';
import { number } from '../typing/number.js';

export const StreamListItem = object('StreamListItem', {
  tenant: string(),
  realm: string(),
  version: string(),
  pattern: string(),
  run: number(),
});
export type StreamListItem = TypeOf<typeof StreamListItem>;
