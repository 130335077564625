import { createType } from './create-type.js';
import { DateTypeDescription } from './date-type-description.js';

export function getDate(val: unknown): Date | null {
  if (typeof val === 'boolean') {
    return null;
  }
  if (val instanceof Date) {
    return val;
  } else if (Object.prototype.toString.call(val)) {
    const date = new Date(val as Date);
    if (!isNaN(date.getTime())) {
      return date;
    }
  } else if (typeof val === 'string') {
    const date = new Date(val);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }

  return null;
}

export const date = () =>
  createType<DateTypeDescription>({
    description: { type: 'date' },
  });
