import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';
import { JsonSchemaDescription } from '../rpc/server/openapi/json-schema-description.js';

export const EventSource = object('EventSource', {
  event: string(),
  schema: JsonSchemaDescription,
  stream: object('EventSourceStream', {
    pattern: string(),
    version: string(),
  }),
});

export type EventSource = TypeOf<typeof EventSource>;
