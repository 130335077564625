import { FetchHttpAdapter } from './fetch-http-adapter.js';
import { HttpClient } from './http-client.js';
import { RuntimeEnv } from '../../runtime-env.js';
import { HttpClientResponse } from './http-client-response.js';
import { createHttpClient } from './http-client-impl.js';

export function fetchHttpClient(opts: {
  baseUrl?: string;
  env: RuntimeEnv;
}): HttpClient<HttpClientResponse> {
  return createHttpClient(new FetchHttpAdapter(opts.env), {
    baseUrl: opts.baseUrl ?? '',
    query: {},
    body: null,
    headers: {},
    paths: [],
    middlewares: [],
  });
}
