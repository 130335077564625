import { FetchHttpClientResponse } from './fetch-http-client-response.js';
import { HttpAdapter } from './http-adapter.js';
import { RuntimeEnv } from '../../runtime-env.js';
import { HttpClientConfig } from './http-client-config.js';
import { HttpMethod } from './http-method.js';

export class FetchHttpAdapter implements HttpAdapter<FetchHttpClientResponse> {
  constructor(private env: RuntimeEnv) {}

  async send(
    config: HttpClientConfig,
    method: HttpMethod,
    signal: AbortSignal,
  ): Promise<FetchHttpClientResponse> {
    const env = this.env;
    const headers = new Headers();
    for (const [key, values] of Object.entries(config.headers)) {
      if (typeof values === 'string') {
        headers.set(key, values);
      } else {
        for (const value of values) {
          headers.append(key, value);
        }
      }
    }
    const searchParams = new URLSearchParams();
    for (const [key, values] of Object.entries(config.query)) {
      if (typeof values === 'string') {
        searchParams.set(key, values);
      } else {
        for (const value of values) {
          searchParams.append(key, value);
        }
      }
    }
    const response = await fetch(
      new URL(
        config.paths.join('/') +
          (Object.keys(config.query).length > 0
            ? `?${searchParams.toString()}`
            : ''),
        config.baseUrl,
      ).toString(),
      {
        method,
        headers,
        body: config.body,
        signal,
      },
    );
    return {
      statusCode: response.status,
      bodyAsBlob(): Promise<Blob> {
        return response.blob();
      },
      async *bodyAsEventStream(): AsyncGenerator<string> {
        if (!response.body) {
          return;
        }

        const reader = response.body.pipeThrough(env.decoder()).getReader();

        while (!signal.aborted) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }

          const parts = (value as string).split('\n\n').filter((s) => !!s);
          for (const part of parts) {
            yield part;
          }
        }
      },
      headers(): { [p: string]: string | string[] } {
        const result: { [key: string]: string | string[] } = {};
        response.headers.forEach((value, key) => {
          result[key] = value;
        });
        return result;
      },
      header(name: string): string | string[] | null {
        const headers = response.headers
          .get(name)
          ?.split(',')
          .map((h) => h.trim());
        if (!headers) {
          return null;
        }
        if (headers.length === 1) {
          return headers[0] ?? null;
        }
        return headers;
      },
      hasHeaderValue(name: string, value: string): boolean {
        const headers = response.headers
          .get(name)
          ?.split(',')
          .map((h) => h.trim());
        if (!headers) {
          return false;
        }
        return headers.some((h) => h === value);
      },
      headerAsString(name: string): string | null {
        return response.headers.get(name);
      },
      bodyAsString(): Promise<string> {
        return response.text();
      },
    };
  }
}
