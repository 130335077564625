import { defineRpc } from '@aion/core/rpc/server/server.js';
import { cmd } from '@aion/core/rpc/client/cmd.js';
import { RealmDeclaration } from '@aion/core/realm/realm-declaration.js';
import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { optional } from '@aion/core/typing/optional.js';
import { StreamListItem } from '@aion/core/realm/stream-list-item.js';
import { ReplayResult } from '@aion/core/management/replay-result.js';

export const UploadResult = object('UploadResult', {
  message: string(),
});

export const ReplayRequest = object('ReplayRequest', {
  realm: string(),
  version: string(),
  pattern: string(),
  tenant: string(),
  token: string(),
});

export const IterateStreamRequest = object('IterateStreamRequest', {
  realm: optional(string()),
  version: optional(string()),
  pattern: optional(string()),
  tenant: string(),
  token: string(),
});

export const rpcManagementDefinition = defineRpc({
  upload: cmd(RealmDeclaration.type).returns(UploadResult),
  replayStream: cmd(ReplayRequest).returns(ReplayResult),
  iterateStreams: cmd(IterateStreamRequest).continuous(StreamListItem),
});
