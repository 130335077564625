import { RuntimeEnv } from '@aion/core/runtime-env.js';

export function createBrowserEnv(): RuntimeEnv {
  return {
    encoder(): { encode(val: string): Uint8Array } {
      return new TextEncoder();
    },
    crypto() {
      return crypto;
    },
    env(name: string): string | null {
      const value = name in window ? (window as any)[name] : null;
      if (value && typeof value === 'string') {
        return value;
      }
      return null;
    },
    decoder(): any {
      return new TextDecoderStream();
    },
  };
}
