<div class="mx-auto w-full px-4">
  @if (session(); as authSession) {
    @if (authSession.type === 'authenticated') {
      <div class="flex justify-end">
        <button
          (click)="logout()"
          class="text-right flex items-center space-x-2">
          <div>
            <div class="text-emerald-100">
              {{ authSession.payload['name'] ?? authSession.payload['email'] }}
            </div>
            <div class="text-sm text-emerald-100">Logout</div>
          </div>
          @if (authSession.payload['picture']; as pic) {
            <img [src]="pic" alt="profile" class="rounded-full h-10 w-10" />
          }
        </button>
      </div>
    }
  } @else {
    <a [routerLink]="'/login'">Login</a>
  }
</div>
