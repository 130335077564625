import { string } from '@aion/core/typing/string.js';
import { TypeOfEvent } from '@aion/core/typing/type-of.js';
import { EventAuth } from '@aion/core/event-store/event-auth.js';
import { declareEvent } from '@aion/core/storage/declare-state.js';

export const InviteUserEvent = declareEvent({
  id: string(),

  groupName: string(),
  groupId: string(),

  name: string(),
  email: string(),

  user: EventAuth,
});

export type InviteUserEvent = TypeOfEvent<typeof InviteUserEvent>;
