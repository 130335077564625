import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TodoStateService } from '../todo-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  todoState = inject(TodoStateService);
  router = inject(Router);

  loginWithGoogle() {
    return this.todoState.management.session.navigateToLogin('google');
  }

  async loginWithCustom(username: string, password: string) {
    await this.todoState.management.session.login('custom', username, password);
    await this.router.navigate(['/personal']);
  }

  async registerWithCustom(username: string, password: string) {
    await this.todoState.management.session.register(
      'custom',
      username,
      password,
    );
    await this.router.navigate(['/personal']);
  }

  loginWithMicrosoft() {
    return this.todoState.management.session.navigateToLogin('microsoft');
  }
}
