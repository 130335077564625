import { Emitter } from './emitter.js';
import { Listener } from './listener.js';

export function createEmitter<T>(): Emitter<T> {
  const listeners: Listener<T>[] = [];

  return {
    on: (cb: Listener<T>, signal: AbortSignal) => {
      signal.addEventListener(
        'abort',
        () => {
          const index = listeners.indexOf(cb);
          if (index >= 0) {
            listeners.splice(index, 1);
          }
        },
        { once: true },
      );
      listeners.push(cb);
    },
    emit(val: T) {
      for (const listener of listeners) {
        listener(val);
      }
    },
  };
}
