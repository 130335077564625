import { RuntimeEnv } from '../runtime-env.js';

export async function sha1(env: RuntimeEnv, value: string): Promise<string> {
  const encoder = env.encoder();
  const msgBuffer = encoder.encode(value);

  // hash the message
  const hashBuffer = await env.crypto().subtle.digest('SHA-1', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}
