export class CustomError extends Error {
  constructor(
    message: string,
    public source: unknown,
    public data: { [key: string]: unknown },
  ) {
    super(
      `${message}${source ? ` ${source}` : ''}${Object.keys(data).length > 0 ? ` (${JSON.stringify(data)})` : ''}`,
    );
  }
}
