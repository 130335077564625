import {
  AuthDefinition,
  JWTAuthDeclarations,
} from '@aion/core/realm/auth-definition.js';
import { CustomError } from '@aion/core/custom-error.js';
import { OAuthTransferStateData } from './o-auth-transfer-state-data.js';
import { JwtSessionData } from '@aion/client/auth/jwt-session-data.js';
import { createLocalSession } from './create-local-session.js';
import { AuthParams } from '@aion/client/auth/auth-params.js';
import { buildJwtSessionData } from '@aion/client/auth/build-jwt-session-data.js';
import { createLazy } from '@aion/core/lazy/create-lazy.js';
import { createJwtSessionHandler } from '@aion/client/auth/create-jwt-session-handler.js';
import { RealmDefinition } from '@aion/core/realm/realm-definition.js';
import { parseJwt } from '@aion/core/authentication/jwt/parse-jwt';
import { UnauthenticatedSession } from './unauthenticated-session.js';
import { AuthenticatedSession } from './authenticated-session.js';
import { OAuthOptions } from './o-auth-options.js';
import {
  OAuthEventData,
  OAuthRemoteInterface,
} from './o-auth-remote-interface.js';
import { createRpcClient } from '@aion/client/api/create-rpc-client.js';
import { getOrCreate } from '@aion/core/utils/get-or-create.js';
import { JwtSession } from '@aion/client/auth/jwt-session.js';
import { JwtPayload } from '@aion/core/authentication/jwt/jwt-payload.js';
import { resolvedLazy } from '@aion/core/lazy/resolved-lazy.js';
import { createEmitter } from '@aion/core/emitter/create-emitter.js';
import { JwtSessionStorage } from '@aion/client/auth/jwt-session-storage.js';

async function loadTransferSession(
  realm: RealmDefinition<any>,
  params: AuthParams,
  options: OAuthStorage,
): Promise<JwtSessionData | null> {
  const searchParams = new URLSearchParams(
    window.location.search.substring(window.location.search.indexOf('?')),
  );
  const state = searchParams.get('state');
  const code = searchParams.get('code');

  if (!state || !code) {
    return await options.sessionStorage.load();
  }

  const transferState = await options.transferStorage.load();
  if (!transferState) {
    return await options.sessionStorage.load();
  }

  await options.transferStorage.clear();

  const api = createRpcClient({ url: params.url, env: params.env });
  const response = await api['auth.authorize'].call(
    {
      realm: transferState.realm,
      tenant: transferState.tenant,
      code,
      redirectUri: transferState.redirectUri,
      provider: transferState.provider,
    },
    params.signal,
  );
  const sessionData = buildJwtSessionData(
    realm,
    params.tenant,
    transferState.provider,
    response.result,
  );
  await options.sessionStorage.save(sessionData);
  return sessionData;
}

interface OAuthStorage {
  sessionStorage: JwtSessionStorage<JwtSessionData>;
  transferStorage: JwtSessionStorage<OAuthTransferStateData>;
}

function createOAuthInterface<TAuth extends AuthDefinition>(
  realm: RealmDefinition<TAuth>,
  params: AuthParams,
  opts: OAuthStorage,
): OAuthRemoteInterface<TAuth> {
  const emitter = createEmitter<OAuthEventData>();

  let currentSession: UnauthenticatedSession | AuthenticatedSession = {
    type: 'unauthenticated',
  };
  let session: JwtSession | null = null;
  let lazySessionData = createLazy(async () => {
    const sessionData = await loadTransferSession(realm, params, {
      sessionStorage: opts.sessionStorage,
      transferStorage: opts.transferStorage,
    });
    currentSession = sessionToAuthState(sessionData);
    emitter.emit(currentSession);

    if (sessionData) {
      handleSession(sessionData);
    }

    return sessionData;
  });

  const api = createRpcClient({ url: params.url, env: params.env });

  function handleSession(sessionData: JwtSessionData) {
    session = createJwtSessionHandler(api, {
      data: sessionData,
      abort: new AbortController(),
    });

    session.on((evt) => {
      emitter.emit(evt);
      if (evt.error instanceof CustomError) {
        if (evt.error.data['error'] === 'no session') {
          logout();
        }
      }
    }, params.signal);
  }

  async function updateSession(sessionData: JwtSessionData) {
    await opts.sessionStorage.save(sessionData);

    lazySessionData = resolvedLazy(sessionData);
    session?.close();
    handleSession(sessionData);

    currentSession = sessionToAuthState(sessionData);
    emitter.emit(currentSession);
  }

  function sessionToAuthState(
    session: JwtSessionData | null,
  ): AuthenticatedSession | UnauthenticatedSession {
    if (session) {
      const payload = parseJwt(session.accessToken, 1, JwtPayload);
      return {
        type: 'authenticated',
        auth: {
          iss: payload.iss,
          aud: payload.aud,
          sub: payload.sub,
        },
        payload,
      };
    } else {
      return { type: 'unauthenticated' };
    }
  }

  async function logout() {
    await opts.sessionStorage.clear();
    await opts.transferStorage.clear();
    await session?.close();
    session = null;
    emitter.emit({ type: 'unauthenticated' });
  }

  return {
    sessions: {
      async resolveToken(): Promise<string | undefined> {
        await lazySessionData.resolve();
        if (!session) {
          return undefined;
        }

        return session.resolveToken();
      },
    },
    on(cb: (evt: OAuthEventData) => void, signal: AbortSignal): void {
      emitter.on(cb, signal);
      cb(currentSession);
    },
    async login(
      provider: keyof JWTAuthDeclarations<TAuth> & string,
      username: string,
      password: string,
    ): Promise<void> {
      const config = realm.auth[provider];

      if (!config) {
        throw new CustomError('unknown provider', null, {
          provider,
        });
      }

      const api = createRpcClient({ url: params.url, env: params.env });
      const response = await api['auth.login'].call(
        {
          tenant: params.tenant,
          realm: realm.name,
          provider,
          username,
          password,
        },
        params.signal,
      );
      const sessionData = buildJwtSessionData(
        realm,
        params.tenant,
        provider,
        response.result,
      );
      await updateSession(sessionData);
    },
    async register(
      provider: keyof JWTAuthDeclarations<TAuth> & string,
      username: string,
      password: string,
    ): Promise<void> {
      const config = realm.auth[provider];

      if (!config) {
        throw new CustomError('unknown provider', null, {
          provider,
        });
      }

      const api = createRpcClient({ url: params.url, env: params.env });
      const response = await api['auth.register'].call(
        {
          tenant: params.tenant,
          realm: realm.name,
          provider,
          username,
          password,
        },
        params.signal,
      );
      const sessionData = buildJwtSessionData(
        realm,
        params.tenant,
        provider,
        response.result,
      );
      await updateSession(sessionData);
    },
    navigateToLogin: async (provider) => {
      const state = Math.random().toString(36);
      const config = realm.auth[provider];

      if (!config) {
        throw new CustomError('unknown provider', null, {
          provider,
        });
      }

      if (config.type !== 'openid') {
        throw new CustomError('expected openid provider', null, {
          provider,
        });
      }

      const qs = {
        ...config.authorize.queryParams,
        state,
        redirect_uri: window.location.origin + window.location.pathname,
      };

      await opts.transferStorage.save({
        redirectUri: qs.redirect_uri,
        provider: provider,
        tenant: params.tenant,
        realm: realm.name,
      });

      window.location.href = `${config.authorize.url}?${Object.entries(qs)
        .map(([key, value]) => `${key}=${String(value)}`)
        .join('&')}`;
    },
    logout,
    async resolveAuth(): Promise<
      UnauthenticatedSession | AuthenticatedSession
    > {
      const session = await lazySessionData.resolve();
      return sessionToAuthState(session);
    },
  };
}

export function createOAuthStorage<TAuth extends AuthDefinition>(
  realm: RealmDefinition<TAuth>,
  opts: OAuthOptions,
): (params: AuthParams) => OAuthRemoteInterface<TAuth> {
  const interfaces: Record<string, OAuthRemoteInterface<any>> = {};

  return (params) => {
    const key = `${params.tenant}:${params.url}`;
    const sessionStorage = (opts?.sessionStorageFactory ?? createLocalSession)(
      `auth_session_${key}`,
      JwtSessionData,
    );

    const transferStorage = (
      opts?.transferStorageFactory ?? createLocalSession
    )(`auth_transfer_${key}`, OAuthTransferStateData);
    return getOrCreate(interfaces, key, () =>
      createOAuthInterface(realm, params, { sessionStorage, transferStorage }),
    );
  };
}
