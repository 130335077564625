import { CustomError } from '../custom-error.js';

export function getOrFail<
  T extends { [key: string]: any },
  K extends keyof T & string,
>(map: T, key: K): T[K] {
  const value = map ? map[key] : undefined;
  if (!value) {
    throw new CustomError('missing key', null, {
      key,
    });
  }
  return value;
}

export async function getOrFailAsync<T>(
  map: { get(key: string): Promise<T | null> },
  key: string,
): Promise<T> {
  const value = await map.get(key);
  if (!value) {
    throw new CustomError('missing key', null, {
      key,
    });
  }
  return value;
}
