import { RpcFetchClientOptions } from '@aion/core/rpc/client/client.js';
import { rpcApiDefinition } from './rpc-api-definition.js';
import { rpcApiHttpDefinition } from './rpc-api-http-definition.js';
import { rpcManagementDefinition } from './rpc-management-definition.js';
import { rpcManagementHttpDefinition } from './rpc-management-http-definition.js';
import { rpcFetchClient } from '@aion/core/rpc/client/rpc-fetch-client.js';

export function createRpcClient(opts: RpcFetchClientOptions) {
  return rpcFetchClient(rpcApiDefinition, rpcApiHttpDefinition, opts);
}

export type RpcClient = ReturnType<typeof createRpcClient>;

export function createRpcManagementClient(opts: RpcFetchClientOptions) {
  return rpcFetchClient(
    rpcManagementDefinition,
    rpcManagementHttpDefinition,
    opts,
  );
}

export type RpcManagementClient = ReturnType<typeof createRpcManagementClient>;
